p {
  color: #455560;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #458897;
}

.ant-badge-status-processing {
  background-color: rgb(85 153 255);
}

.ant-badge-status-processing::after {
  border: 1px solid rgb(68 122 255);
}

.btn-abst {
  position: absolute !important;
  z-index: 1;
  right: 1.3rem;
  top: 3rem;
}

.inv-select {
  height: auto;
  font-size: 12px;
}
.inv-select .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  /* white-space: nowrap; */
  white-space: normal;
  word-break: break-all;
}

.sticky-card {
  position: sticky !important;
  z-index: 8;
  top: 12.3rem;
}

.fs-12 .ant-table table,
.fs-12 {
  font-size: 12px;
}

.ant-form-item-control-input {
  min-height: auto;
}

.blank-footer {
  position: absolute;
  bottom: 2rem;
  margin: auto;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.397);
}

.countdown-otp .ant-statistic-content-value,
.ant-statistic-content-prefix {
  font-size: 14px !important;
  color: rgba(114, 132, 154, 0.7) !important;
}
